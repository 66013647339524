import React, { useEffect } from 'react'


const SAME_CLASS="block px-4 py-2  text-white text-xs xl:text-sm 2xl:text-base   hover:text-green-primary"

const Subitems = ({setShowOurServices}) => {



    return (
        <div className='flex min-w-640'onMouseEnter={()=>setShowOurServices(true)}  onMouseLeave={() => setShowOurServices(false)}>
              <div id="dropdownNavbar" class="transition text-xs xl:text-sm 2xl:text-base  font-normal bg-blue-primary divide-y divide-gray-100 shadow w-44 dark:bg-gray-700 dark:divide-gray-600">
                <ul class="py-2 text-sm text-gray-700 dark:text-gray-400" aria-labelledby="dropdownLargeButton">
                    <li>
                        <a href="/services/custom-software-development" class={SAME_CLASS}>Custom Software Development</a>
                    </li>
                    <li>
                        <a href="/services/digital-transformation-services" class={SAME_CLASS}>Digital Transformation</a>
                    </li>
                    <li>
                        <a href="/services/software-quality-assurance" class={SAME_CLASS}>QA and Testing Services</a>
                    </li>
                    <li>
                        <a href="/services/mobile-application-development" class={SAME_CLASS}>Mobile Application Development</a>
                    </li>
                    <li>
                        <a href="/services/ui-and-ux-design"class={SAME_CLASS}>UI and UX Design</a>
                    </li>
                    <li>
                        <a href="/services/maintenance-and-support" class={SAME_CLASS}>Maintenance &  Support Services </a>
                    </li>
                </ul>
                {/* <div class="py-1">
                    <a href="#" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-400 dark:hover:text-white">Sign out</a>
                </div> */}
            </div>
            <div id="dropdownNavbar" class="font-normal bg-blue-primary divide-y divide-gray-100 shadow w-44 dark:bg-gray-700 dark:divide-gray-600">
                <ul class="py-2 text-sm text-gray-700 dark:text-gray-400" aria-labelledby="dropdownLargeButton">
                    <li>
                        <a href="/metaverse" class={SAME_CLASS}>Metaverse</a>
                    </li>
                    <li>
                        <a href="/augmented-reality" class={SAME_CLASS}>Augmented Reality</a>
                    </li>
                    <li>
                        <a href="/services/blockchain-solutions" class={SAME_CLASS}>Block Chain</a>
                    </li>
                    <li>
                        <a href="/3d-modelling"  class={SAME_CLASS}>3d Modeling</a>
                    </li>
                    <li>
                        <a href="/services/devops-services" class={SAME_CLASS}>Devops</a>
                    </li>
                    <li>
                        <a href="/services/big-data-consulting-services" class={SAME_CLASS}>Big Data</a>
                    </li>
                </ul>
                {/* <div class="py-1">
                    <a href="#" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-400 dark:hover:text-white">Sign out</a>
                </div> */}
            </div>
          
        </div>
    )
}

export default Subitems