export const links = [
    // {
    //     title: "SERVICES",
    //     to: "/services"
    // },
    {
        title: "ABOUT US",
        to: "/about-us"
    },
    {
        title: "HOW WE DELIVER",
        to: "/how-we-deliver"
    },
    
    {
        title: "CONTACT US",
        to: "/contact-us"
    },
    {
        title: "BLOG",
        to: "https://astutesoftwares.com/blog"
    },
    {
        title: "CAREERS",
        to: "/careers"
    },
    
]