import Navbar from "../components/Navbar/index";
import Sidebar from "../components/Sidebar/index";
import Footer from "../components/Footer/index";
import React, { useState, Suspense, useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

//   component imports
import Loader from "../components/Loader";
import Overlay from "../components/Overlay";
import Cookie from "../components/Cookie";
// import TidioChat from "../components/TidioChat";
// import PageNotFound from "../pages/PageNotFound";
//   component imports

// Page imports
const Home = React.lazy(() => import("../pages/Home/Desktop"));
const AR = React.lazy(() => import("../pages/ARLandingPage/ARLandingPage"));
const ArViewer = React.lazy(() => import("../pages/ArViewer"));
const Language = React.lazy(() =>
  import("../pages/Home/Desktop/LanguagePage/language")
);
const Service = React.lazy(() => import("../pages/Services/Desktop/index"));
const AboutUS = React.lazy(() => import("../pages/About-US/Desktop/index"));
const ContactUS = React.lazy(() => import("../pages/Contact-us"));
const SEOPage = React.lazy(() =>
  import("../pages/Home/Desktop/SEO Services/seoServices")
);
const HowWeDeliver = React.lazy(() =>
  import("../pages/HowWeDeliver/HowWeDeliver")
);
const Careers = React.lazy(() => import("../pages/Careers/Careers"));
const DevService = React.lazy(() =>
  import("../pages/DetailServicePages/WebDevService/WebDevService")
);
const UnderConstruction = React.lazy(() =>
  import("../pages/UnderConstruction/UnderConstruction")
);
// const AnimationPage = React.lazy(() => import("../pages/AnimationPage/Animation"));
const AuthPage = React.lazy(() => import("../pages/SignUp/SignUp"));
// Page imports

// Technology detail page imports
const AngularTechnology = React.lazy(() =>
  import("../pages/Technologies/Angular/Angular")
);
const LaravelTechnology = React.lazy(() =>
  import("../pages/Technologies/Laravel/Laravel")
);
const ReactTechnology = React.lazy(() =>
  import("../pages/Technologies/React/ReactTechnology")
);
const NodeTechnology = React.lazy(() =>
  import("../pages/Technologies/Node/Node")
);
const VueTechnology = React.lazy(() =>
  import("../pages/Technologies/VueJs/VueJs")
);
const KotlineTechnology = React.lazy(() =>
  import("../pages/Technologies/Kotline/Kotline")
);
const DjangoTechnology = React.lazy(() =>
  import("../pages/Technologies/Django/Django")
);
// Technology detail page imports

// Detail service Page imports
const WebDevService = React.lazy(() =>
  import("../pages/DetailServicePages/WebDevService/WebDevService")
);
const QAService = React.lazy(() =>
  import("../pages/DetailServicePages/QAServices/QAService")
);
const DTService = React.lazy(() =>
  import(
    "../pages/DetailServicePages/DigitalTransformation/DigitalTransformation"
  )
);
const MobileAppDevService = React.lazy(() =>
  import("../pages/DetailServicePages/MobileAppDev/MobileAppDev")
);
const UxDesignService = React.lazy(() =>
  import("../pages/DetailServicePages/UxDesign/UxDesign")
);
const MaintenanceSupport = React.lazy(() =>
  import("../pages/DetailServicePages/MaintenanceSupport/MaintenanceSupport")
);
const BigDataService = React.lazy(() =>
  import("../pages/DetailServicePages/BigDataService/BigDataService")
);
const BlockChainService = React.lazy(() =>
  import("../pages/DetailServicePages/BlockChainService/BlockChainService")
);
const DevopService = React.lazy(() =>
  import("../pages/DetailServicePages/DevopService/DevopService")
);
const TimeAndMaterial = React.lazy(() =>
  import("../pages/DetailServicePages/TimeAndMaterial/TimeAndMaterial")
);
const CouponPage = React.lazy(() => import("../pages/CouponPage/CouponPage"));
const ArServices = React.lazy(() =>
  import("../pages/Services/Desktop/ArServices")
);
const MetverseServices = React.lazy(() =>
  import("../pages/Services/Desktop/MetverseServices")
);
const ThreedModeling = React.lazy(() =>
  import("../pages/Services/Desktop/ThreedModeling")
);
const ModelsPage = React.lazy(() =>
  import("../pages/Services/Desktop/ModelPage")
);
const Portfolio = React.lazy(() => import("../pages/Portfolio"));
// Detail service Page imports

// Game page import
const FallingGems = React.lazy(() => import("../pages/FallingGems"));
// Game page import

// policy pages
const CookiePolicy = React.lazy(() => import("../pages/CookiePolicy"));
// policy pages

// privacy policy pages
const PrivacyPolicy = React.lazy(() => import("../pages/PrivacyPolicy"));
// privacy policy pages

// landing pages
const LandingPage = React.lazy(() => import("../pages/LandingPage"));
const ItalianLandingPage = React.lazy(() => import("../pages/ItalianLandingPage"));
// landing pages

const PageNotFound = React.lazy(() => import("../pages/PageNotFound"));

const DirectContactUs = React.lazy(() => import("../pages/DirectContact"));
const ItalianDirectContactUs = React.lazy(() =>
  import("../pages/DirectContact/ItalianContactUs")
);

const BaseRoutes = () => {
  const [sidebar, setSidebar] = useState(false);
  const [overlay, setOverlay] = useState(false);
  const [gameDownload, setGameDownload] = useState(false);
  const [gamePage, setGamePage] = useState(false);
  const [cookie, setCookie] = useState(
    localStorage.getItem("cookies") ? false : true
  );
  const [showHeaderFooter, setShowHeaderFooter] = useState(true);

  return (
    // <div className="flex flex-col h-full pt-28">
    <Suspense fallback={<Loader />}>
      {/* Showing cookie bar */}
      {cookie ? <Cookie setCookie={setCookie} /> : null}

      {sidebar ? <Overlay /> : null}
      {showHeaderFooter && (
        // !gamePage &&
        <>
          <Navbar sidebar={sidebar} setSidebar={setSidebar} />
          <Sidebar sidebar={sidebar} setSidebar={setSidebar} />
        </>
      )}

      {/* Tidio Chat Bot */}
      {/* <TidioChat /> */}

      {/* <div className="flex-grow"> */}
      <Switch>
        <Route path="/turin-startup-campaign" exact>
          <ItalianLandingPage />
        </Route>
        <Route path="/usa-startups" exact>
          <LandingPage />
        </Route>
        <Route path="/" exact>
          <Home gameDownload={gameDownload} setGameDownload={setGameDownload} />
        </Route>
        <Route path="/ar-solutions" exact>
          <AR />
        </Route>
        <Route path="/ar-solutions/:id" exact>
          <ArViewer setShowHeaderFooter={setShowHeaderFooter} />
        </Route>
        <Route path="/about-us" exact>
          <AboutUS />
        </Route>
        <Route path="/services" exact>
          <Service />
        </Route>
        <Route path="/contact-us" exact>
          <ContactUS />
        </Route>
        <Route path="/request-contact" exact>
          <DirectContactUs setShowHeaderFooter={setShowHeaderFooter} />
        </Route>
        <Route path="/request-contact-it" exact>
          <ItalianDirectContactUs setShowHeaderFooter={setShowHeaderFooter} />
        </Route>
        <Route path="/blogs" exact>
          <UnderConstruction />
        </Route>
        <Route path="/how-we-deliver" exact>
          <HowWeDeliver />
        </Route>
        <Route path="/careers" exact>
          <Careers />
        </Route>
        <Route path="/coming-soon" exact>
          <UnderConstruction />
        </Route>

        <Route path="/coupons" exact>
          <CouponPage />
        </Route>

        <Route path="/auth" exact>
          <AuthPage />
        </Route>
        {/* <Route path="/animation" exact>
                <AnimationPage />
            </Route> */}

        {/* Detail service page routes */}
        <Route path="/services/custom-software-development" exact>
          <WebDevService />
        </Route>
        <Route path="/services/software-quality-assurance" exact>
          <QAService />
        </Route>
        <Route path="/services/digital-transformation-services" exact>
          <DTService />
        </Route>
        <Route path="/services/mobile-application-development" exact>
          <MobileAppDevService />
        </Route>
        <Route path="/services/ui-and-ux-design" exact>
          <UxDesignService />
        </Route>
        <Route path="/services/maintenance-and-support" exact>
          <MaintenanceSupport />
        </Route>
        <Route path="/services/big-data-consulting-services" exact>
          <BigDataService />
        </Route>
        <Route path="/services/blockchain-solutions" exact>
          <BlockChainService />
        </Route>
        <Route path="/services/devops-services" exact>
          <DevopService />
        </Route>

        <Route path="/services/time-and-material-services" exact>
          <TimeAndMaterial />
        </Route>

        <Route path="/augmented-reality" exact>
          <ArServices />
        </Route>

        <Route path="/metaverse" exact>
          <MetverseServices />
        </Route>

        <Route path="/3d-modelling" exact>
          <ThreedModeling />
        </Route>
        {/* <Route path = '/3d-models-and-configurator' exact>
                <ModelsPage/>
            </Route> */}
        <Route path="/portfolio" exact>
          <Portfolio setShowHeaderFooter={setShowHeaderFooter} />
        </Route>

        {/* Detail service page routes */}

        {/* Detail technology page routes  */}
        <Route path="/technologies/angular" exact>
          <AngularTechnology />
        </Route>
        <Route path="/technologies/laravel" exact>
          <LaravelTechnology />
        </Route>
        <Route path="/technologies/react" exact>
          <ReactTechnology />
        </Route>
        <Route path="/technologies/nodejs" exact>
          <NodeTechnology />
        </Route>
        <Route path="/technologies/vue" exact>
          <VueTechnology />
        </Route>
        <Route path="/technologies/kotlin" exact>
          <KotlineTechnology />
        </Route>
        <Route path="/technologies/django" exact>
          <DjangoTechnology />
        </Route>
        {/* Detail technology page routes  */}

        {/* Game page */}
        <Route path="/falling-gem" exact>
          <FallingGems setShowHeaderFooter={setGamePage} />
        </Route>
        {/* Game page */}

        {/* policy pages */}
        <Route path="/cookie-policy" exact>
          <CookiePolicy />
        </Route>
        {/* policy pages */}

        {/* privacy policy pages */}
        <Route path="/privacy-policy" exact>
          <PrivacyPolicy />
        </Route>
        {/* privacy policy pages */}

        {/* only make changes above */}
        <Route path="/404" exact>
          <PageNotFound />
        </Route>

        {/* <Route path="*" element={<Navigate to ="/404" />}>
          <PageNotFound />
        </Route> */}
        <Redirect from="*" to="/404"/>
      </Switch>
      {/* </div> */}

      {/* <div className="flex-shrink-0"> */}
      {showHeaderFooter && <Footer />}
      {/* </div> */}
    </Suspense>
    // </div>
  );
};

export default BaseRoutes;
